<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <login-logo :show-text="true" class="mx-auto"/>
      <form @submit.prevent="submit" class="mt-8 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input v-model="email" id="email-address" name="email" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Email address" />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Password" />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <router-link to="/forgot-password" class="font-medium text-base-600 hover:text-base-500">
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-base-500 group-hover:text-base-400" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
        <error-label v-if="errors.length" :errors="errors"></error-label>
      </form>
    </div>
    <error-notification :show="error != null" :message="error" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { LockClosedIcon } from '@heroicons/vue/solid'
import { required, minLength, email } from 'vuelidate/lib/validators'
import errorLabel from '../components/errorLabel'
import {mapState, mapActions} from 'vuex'
import errorNotification from "@/components/ui/ErrorNotification";
import loginLogo from "@/components/ui/LoginLogo";


export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('auth', ['user']),
    errors() {
      let output = [];
      this.v$.$errors.forEach(e=>{
        output.push(e.$message)
      })
      return output;
    }
  },
  mounted() {
    console.log(this.$store.state.auth)
  },
  data() {
    return {
      email: null,
      password: null,
      error: null
    }
  },
  components: {
    LockClosedIcon,
    errorLabel,
    errorNotification,
    loginLogo
  },
  validations() {
    return {
        email: {
          required: {
            $validator: required,
            $message: 'Email is required'
          },
          email: {
            $validator: email,
            $message: 'Email is not valid'
          },
        },
        password: {
          required: {
            $validator: required,
            $message: 'Password is required'
          },
          minLength: {
            $validator: minLength(8),
            $message: 'Your password must be at least 8 characters'
          }
        },
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      this.v$.$touch()
      if(!this.v$.$invalid) {
        try {
          let res = await this.login({email: this.email, password: this.password})
          this.$router.push('/')
        } catch (e) {
          console.log()
          let msg = e.response.status == 412 ? "User not verified" : e.response.data
          this.error = msg

          setTimeout(()=>{
            this.error = null
          }, 3000)
        }
      }
    }
  }
}
</script>